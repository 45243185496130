(function () {

	angular.module('Plania').controller('LocalAdSynchronizationController', ['$scope', 'Repository', 'ColumnFormatService', controller]);

	function controller($scope, repository, columnFormatService) {

		$scope.personFields = [];
		$scope.userFields = [];
		$scope.activeDirectoryFields = [];
		$scope.dropDownOptions = [];
		$scope.additionalUserMappings = {};
		$scope.additionalPersonMappings = [];
		$scope.testConfiguration = false;
		$scope.testModel = {
			userGroupToTest: null,
			testResults: null
		};

		repository.getSingleWithNoParameter(repository.apiData.scheduledJob.endpoint.localADSync.mappingFields).then(function (response) {
			$scope.personFields = response.PersonFields;
			$scope.userFields = response.UserFields;
			$scope.activeDirectoryFields = response.ActiveDirectoryFields;
		}, function (error) {
			repository.showError(error);
		});

		repository.getSingleWithNoParameter(repository.apiData.scheduledJob.endpoint.localADSync.dropDownOptions).then(function (response) {
			$scope.dropDownOptions = response;
		}, function (error) {
			repository.showError(error);
		});

		$scope.setTestConfiguration = function () {
			$scope.testConfiguration = !$scope.testConfiguration;
		};

		$scope.getUsersInGroup = function () {
			if (!$scope.testModel.userGroupToTest) return;
			repository.getWithUrlParameter(repository.apiData.scheduledJob.endpoint.localADSync.usersInGroup,"groupName=" + $scope.testModel.userGroupToTest).then(function (response) {
				$scope.testModel.testResults = response;
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.addNewMapping = function (collection) {
			collection.push({
				key: "",
				value: "",
				type: "mappedAttribute"
			});
		};

		$scope.removeMapping = function (collection, mapping) {
			_.remove(collection, function(m) {
				return m.$$hashKey === mapping.$$hashKey;
			});
		};

		$scope.toggleMappingType = function (mapping) {
			if (mapping.type === "mappedAttribute") {
				mapping.type = "customAttribute";
			} else {
				mapping.type = "mappedAttribute";
			}
		};
	}
})();
